import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Button, Modal,Table , Badge} from "react-bootstrap";
import Papa from 'papaparse';

function Landing({ children }) {
    const data = [

    {
      "title":"Founder",
      "description":"In 2014, Justin started Pax Culture Studios, a boutique consultancy taking on a general set of clients, from robotics to web development. As most Entrepreneurs discover, specializing is important. During these early years projects like the SIX and Decelium were founded. In 2016, after the publication of Concurrent Markov Decision Processes (CMDP) at the University of Toronto, Justin integrated his CMDP technology into FleetOps as CTO, and did all early technology strategy, recruitment, and investor relations. After recruiting a VP of Engineering in 2018, Justin began the hard foundation work on the Decelium Foundation, and still operates Pax as a consultancy today.",
      "action_title":"action",
      "size":8,
      "action_link":"http://example.com"
    },
    {
      "title":"Academic",
      "description":"Justin’s research area is Decentralized Multi-Agent systems, and he is interested in Information Theory and emergent intelligence within networks that are comprised of  sparsely connected self interested agents. He has three published papers spanning topics related to Markov Decision Processes and human advice. ",
      "action_title":"action",
      "size":4,
      "action_link":"http://example.com"
    },    
    {
      "title":"Principal Engineer",
      "description":"The majority of Justin’s experience is related to Software Engineering, and he has over 16 years in engineering leadership positions. Recently he has been a Staff Engineer at Hypotenuse labs, where he help launch products that, on launch day, supported over 2.5 million dollars of sales and 27 thousand concurrent users (millions per day). He is used to coordinating teams of over 10 technical engineers, where usually 3-5 are direct reports. He has also held Lecturing positions at the University of Toronto, and has been a research visiting scientist, technical expert for Members of Parliament. Overall Justin has held several senior engineering positions spanning robotics, AI, and web development",
      "action_title":"action",
      "size":5,
      "action_link":"http://example.com"
    },
    {
      "title":"Writer",
      "description":"Justin has written Misanthropy, and Worship, speculative fiction books about the place humanity holds in a rapidly evolving world. Misanthropy is a collection of short stories, freely available for PDF download or purchasable online. Worship is a work in progress, and if you ask Justin, he can send you some advance chapters for review!",
      "action_title":"action",
      "size":3,
      "action_link":"http://example.com"
    },    
    {
      "title":"Activist",
      "description":"Justin's major long term interest is technoactivism. Specifically the betterment of life though improving our relationship with technology. Justin can be found at rallies, festivals, and other community events. He is open to sponsoring community events if the goals are aligned, and if you need a technical volunteer you should message him.",
      "action_title":"action",
      "size":4,
      "action_link":"http://example.com"
    },        
  ]
    return (
      <Container>
      <Row >
        {data.map((item, index) => (
          <Col xs={12} md={item.size} key={index} className="mb-4"> 
              <h1>{item.title}</h1>
              <p>{item.description}</p>
          </Col>
        ))}
      </Row>
      {children}
    </Container>
  );
}

export default Landing;
