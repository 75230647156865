import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Button, Modal,Table , Badge} from "react-bootstrap";
import Papa from 'papaparse';

function ProjectTable({ children,project }) {
  const [rotateX, setRotateX] = useState(0);
  const [rotateY, setRotateY] = useState(0);
  const [tableData, setTableData] = useState([]);

  const convertCSVToJson = (csvText) => {
    return new Promise((resolve, reject) => {
      Papa.parse(csvText, {
        header: true, // Use the first row as headers
        skipEmptyLines: true, // Skip empty lines in the CSV file
        complete: function(results) {
          resolve(results.data); // Resolve with the parsed data
        },
        error: function(error) {
          reject(error); // Reject the promise on error
        }
      });
    });
  };
  
  
  useEffect(() => {
    const handleMouseMove = (event) => {
      const x = event.clientX - window.innerWidth / 2;
      const y = event.clientY - window.innerHeight / 2;
      setRotateX(-y / 2000);
      setRotateY(x / 1000);
    };
    window.addEventListener('mousemove', handleMouseMove);

  function preprocessData(data) {
    const processedData = data.map(item => ({
      ...item,
      start: formatDate(item.start),
      end: formatDate(item.end)
    })).sort((a, b) => new Date(b.end) - new Date(a.end)); // Sort descending by start date
    return processedData;
  }
  
  function combineData(data) {
    return data.reduce((acc, item) => {
      const formattedItem = item;
      if (formattedItem.show != 1)
        return acc;
      // Check if the company already exists in the accumulator
      if (!acc[formattedItem.name]) {
        // If not, initialize it
        acc[formattedItem.name] = {
          name:formattedItem.name,
          description: "None",
          roles: []
        };
      }
      if (formattedItem.project_name.length == 0)
      {
        acc[formattedItem.name].description = formattedItem.project_description;
      }
      else
      {
        // Append the current item's role to the company's roles array
        acc[formattedItem.name].roles.push({
          project_name: formattedItem.project_name,
          project_link: formattedItem.project_link,
          project_description: formattedItem.project_description,
          tags: formattedItem.tags,
          start: formattedItem.start,
          end: formattedItem.end
        });
      }
      return acc;
    }, {});
  }

    // Fetch the CSV file from the public directory
    fetch('projects.csv')
    .then(response => response.text())  // Directly read the response as text
    .then(csvText => {
      console.log(csvText);
      convertCSVToJson(csvText).then(data => {  // Assuming convertCSVToJson accepts a CSV string
        console.log(data);
        /// &#172; How do I replace / render any special entities in the code?
        data = preprocessData(data);
        const final_data = combineData(data);
        setTableData(Object.values(final_data));  // Update state with parsed data
      });
    })
    .catch(error => {
      console.error('Error fetching CSV file:', error);
    });  

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);
  
  function formatDate(dateString) {
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    const lowerCasedDate = dateString.toLowerCase(); // Convert to lower case to handle case insensitivity
  
    if (lowerCasedDate.includes("present")) {
      return new Date().toLocaleDateString('en-US', options);
    } else {
      return new Date(dateString).toLocaleDateString('en-US', options);
    }
  }
  
  const transform = {
    transform: `perspective(600px) rotateX(${rotateX}deg) rotateY(${rotateY}deg)`,
    transition: 'transform 0.1s'
  };
  // company	start	end	name	project_name	project_link	project_description	tags
 // Company section
 // Project card
 // Sub project list
    return (
      <Container>
      <Row>
        {tableData.map((item, index) => (
          <Col xs={12} md={12} key={index} className="mb-4"> 
            <Card style={transform}>
              <Card.Body>
                <Row>
                  <Col xs={12} md={8} key={index} className="mb-4"> 
                    <Card.Title  className="fs-3 " >{item.name}</Card.Title>
                    <Card.Text className="fs-4 " >{item.description}</Card.Text>
                  </Col>
                  <Col xs={0} md={4}  className="mb-4"> 
                  </Col>
                  {item.roles.map((project, index2) => (
                  <Col xs={12} md={4} key={index} className="mb-4"> 
                    <Card.Text>
                      {project.project_link ? (
                        <b><a href={project.project_link}>{project.project_name}:</a> </b>
                      ) : (
                        <b>{project.project_name}: </b>
                      )}
                      {project.project_description}
                    </Card.Text>
                  </Col>
                  ))}
                </Row>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
      {children}
    </Container>
  );
}

export default ProjectTable;
