import React from 'react';
import { Card } from 'react-bootstrap';

function CardComponent({img,title,description,link}) {
  const handleCardClick = () => {
    window.open(link, '_blank');
  };

  return (
    <Card onClick={handleCardClick} className="clickable-card" style={{ cursor: 'pointer' }}>
      <Card.Img variant="top" src={img} alt="Card image" />
      <Card.Body>
        <Card.Title>{title}</Card.Title>
        <Card.Text>
          {description}
        </Card.Text>
      </Card.Body>
    </Card>
  );
}

export default CardComponent;
