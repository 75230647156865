import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Button, Modal } from "react-bootstrap";
import OverlayComponent from './OverlayComponent';
import CardComponent from './CardComponent';
import {ReactTyped} from 'react-typed';

//       

function HireMe({ children}) {
  return (
    <>
      <Container >
        <Row>
            <p className="mt-5"></p>
            <p className="mt-5"></p>
            <Col xs={12} md={8} key={1} className="mb-4"> 
                <h1><b>Get rid of painful <ReactTyped
                  strings={['cloud', 'server', 'storage', 'DevOps','compute']}
                  typeSpeed={50}
                  backDelay={2000}  
                  backSpeed={40}
                  loop
                /> headaches;
                                                    
                  </b></h1>
                <h1>Remove the <ReactTyped
                  strings={['cloud', 'server', 'storage', 'DevOps','compute']}
                  typeSpeed={50}
                  backDelay={2000}  
                  backSpeed={40}
                  loop
                /></h1>
                <p>By using decentralization, you can simplify the legal and technical complexity of business. 
                  Watch our <a href="https://www.youtube.com/watch?v=9lOBqy8BUOo"> Trustless Trust</a> training video, or <a href="https://www.linkedin.com/in/justin-girard/" >lets chat!</a></p>
                <Button href="https://www.linkedin.com/in/justin-girard/">Get in Touch</Button>
            </Col>
        </Row>
        <Row>
            <p className="mt-5"></p>
            <p className="mt-5"></p>
            <p className="mt-5"></p>
            <Col xs={12} md={3} key={1} className="mb-4"> 
              <CardComponent 
              img="youtube.png"
              link="https://www.youtube.com/watch?v=sbSb9pxOTtU"
              title="The Custody Fallacy"
              description="Why do Engineers and Businesses choose to host expensive material, when their clients can host it for free?"              
              ></CardComponent>
            </Col>
            <Col xs={12} md={3} key={1} className="mb-4"> </Col>            
            <Col xs={12} md={6} key={1} className="mb-4"> 
                <h1>My Mission is to end the <b>Custody Fallacy</b></h1>
                <p>A Custody Fallacy is assuming a problem can only be solved within cloud providers, or on prem. People trapped in this mode of thinking have forgotten that users bring their own devices, and that those devices can communicate with each other. </p>
                <Button href="https://www.linkedin.com/in/justin-girard/" >Free Assessement</Button>
            </Col>            
        </Row>      
        <Row>     
        <Col xs={12} md={2} key={1} className="mb-4"></Col>
          <Col xs={12} md={8} key={1} className="mb-4"> 
          <p className="mt-5"></p>
            <p className="mt-5"></p>
              <p className="mt-5"></p>
              <p className="mt-5"></p>
            <h2>
            ... Decentralization allows user-to-user compute sharing ... 
            these apps have no back-end, no legal custodian, 
            and no servers, leading to unprecedented scalability.
            </h2>
          </Col > 
          <Col xs={12} md={2} key={1} className="mb-4"></Col>
          
          <Col xs={2} md={2} key={1} className="mb-4"></Col>
          <Col xs={6} md={5} key={1} className="mb-4"></Col>
          <Col xs={4} md={3} key={1} className="mb-4">Justin Girard, 2017 Consumer Elecronics Show</Col>
          <Col xs={2} md={2} key={1} className="mb-4"></Col>
        </Row>      
        <Row>     
          <p className="mt-5"></p>
          <Col xs={12} md={7} key={1} className="mb-4">
            <h1>Capitalize on Decentralization</h1>
            <p>
            We created this landing page to help us find clients, yes, as being an expert in Multi-Agent systems sets us up to solve unique problems very well. However, we also hope but also be to an education tool for the general community. 
            We hope even if you dont work with us, you take the time to learn about Web 3, beyond meme coins and NFTs.
            </p>
            <Button href="https://www.linkedin.com/in/justin-girard/" >Get your Free Assessment PDF</Button>          
          </Col>
          <Col xs={0} md={1} key={1} className="mb-4"></Col>
          <Col xs={0} md={4} key={1} className="mb-4"> 
          </Col>
        </Row>      
        <Row>
          <Col xs={12} md={3} key={1} className="mb-4"> 
              <CardComponent 
              img="youtube.png"
              link="https://www.youtube.com/watch?v=vl6SaB4zxCc"
              title="Zero Cost Multiplayer"
              description="Decentralized systems can host free multi-user systems, as the users act as their own host."              
              ></CardComponent>
          </Col>                
          <Col xs={0} md={1} key={1} className="mb-4"> </Col>
          <Col xs={12} md={3} key={1} className="mb-4"> 
              <CardComponent 
              img="youtube.png"
              link="https://www.youtube.com/watch?v=fstNfpT0Q2o"
              title="Zero Cost Compliance"
              description="When users cloud-store their own data, corporations do not have to meet the same privacy standards."              
              ></CardComponent>
          </Col>                
          <Col xs={0} md={1} key={1} className="mb-4"> </Col>
          
          <Col xs={12} md={3} key={1} className="mb-4"> 
              <CardComponent 
              img="youtube.png"
              link="https://www.youtube.com/watch?v=9lOBqy8BUOo"
              title="Trustless Trust"
              description="Deep Theoretical review of Trustless Trust, and why that concept underpins not only blockchain, but all trust in society."              
              ></CardComponent>
          </Col>                
          <Col xs={0} md={1} key={1} className="mb-4"> </Col>
          

        </Row>      


        {children}
      </Container>   
    </>       
  );
}

export default HireMe;
