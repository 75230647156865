import React, { useState,useEffect,useRef } from "react";
import { Container, Row, Col, Card, Button, Modal, Nav, Navbar } from "react-bootstrap";
import "./App.css"; // make sure to import the css file
import projects from "./positions.json";
import ThreeCube from './ThreeCube';
import InteractiveCard from './InteractiveCard';
import ResumeTable from './ResumeTable';
import ProjectTable from './ProjectTable';
import Landing from './Landing';
import HireMe from './HireMe';
import { v4 as uuidv4 } from 'uuid';
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";



function Contact ({projects}){
  return (
    <Container>
    <Row>
      {projects.map((project, idx) => (
        <Col key={idx} xs={12} md={4}>
            <InteractiveCard project={project} ></InteractiveCard>
        </Col>
    ))}
    </Row>
    </Container>
  )
}

function App() {
  const [modalInfo, setModalInfo] = useState(null);

  const handleShow = (info) => {
    setModalInfo(info);
  };

  const handleClose = () => {
    setModalInfo(null);
  };

  return (
    <Router>
      <Container  style={{ position: 'relative' }}>
        <Navbar className="mb-5" expand="lg">
          <Navbar.Brand href="/"  >JUSTIN GIRARD</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto">
            <Nav.Link as={Link} to="/projects">Projects</Nav.Link>
              <Nav.Link as={Link} to="/contact">Contact</Nav.Link>
              <Nav.Link as={Link} to="/experience">Experience</Nav.Link>
              <Nav.Link as={Link} to="/hireme">Hire Me</Nav.Link>
            </Nav>
          </Navbar.Collapse>
          <Navbar.Collapse id="basic-navbar-nav2" className="justify-content-end">
            <Nav >
              <Button as={Link} to="https://store.justingirard.com/ ">Store</Button>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
        <Routes>
          <Route path="/contact" element={<><Contact projects={projects}></Contact></> } /> /{/* Display Contact component */}
          <Route path="/experience" element={<><ResumeTable /></>} /> {/* Display ResumeTable component */}
          <Route path="/projects" element={<><ProjectTable /></>} /> {/* Display ResumeTable component */}
          <Route path="/" element={<><Landing /></>} /> {/* Redirect to Contact by default */}
          <Route path="/hireme" element={<><HireMe /></>} /> {/* Redirect to Contact by default */}
        </Routes>
      </Container>
    </Router>
  );
  
}

export default App;
