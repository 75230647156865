import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Button, Modal } from "react-bootstrap";

function InteractiveCard({ children,project }) {
  const [rotateX, setRotateX] = useState(0);
  const [rotateY, setRotateY] = useState(0);

  useEffect(() => {
    const handleMouseMove = (event) => {
      const x = event.clientX - window.innerWidth / 2;
      const y = event.clientY - window.innerHeight / 2;
      setRotateX(-y / 2000);
      setRotateY(x / 1000);
    };

    window.addEventListener('mousemove', handleMouseMove);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  const transform = {
    transform: `perspective(600px) rotateX(${rotateX}deg) rotateY(${rotateY}deg)`,
    transition: 'transform 0.1s'
  };

  return (
    <Card style={{ borderRadius: 1}}>                
    <div style={{ position: 'relative', zIndex: 2 }}>
      <Card.Body>
        <Card.Title>{project.title}</Card.Title>
        <div className="d-flex justify-content-between mt-3">
          <p>&nbsp;</p>
          <Button
            variant="secondary"
            onClick={() => window.open(project.link, "_blank")}
          >
            Visit
          </Button>
          {children}
        </div>
      </Card.Body>
    </div>
    </Card>    
  );
}

export default InteractiveCard;
