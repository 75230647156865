import React, { useRef, useState, useEffect } from "react";
import { Canvas, useFrame } from "@react-three/fiber";
import { InView } from "react-intersection-observer";
import { BufferGeometry, BufferAttribute, Vector3 } from "three";
import { MeshLine, MeshLineMaterial } from "three.meshline";
import { extend } from '@react-three/fiber';

extend({ MeshLine, MeshLineMaterial });

const getLoopCount = (depth) => {
  return 2;
};

const drawLine = (start, end, steps, delay, vertices, callback) => {
  const dx = (end.x - start.x) / steps;
  const dy = (end.y - start.y) / steps;
  const dz = (end.z - start.z) / steps;

  return new Promise((resolve, reject) => {
    const draw = (i) => {
      if (i > steps) {
        resolve();
        return;
      }
      const x = start.x + dx * i;
      const y = start.y + dy * i;
      const z = start.z + dz * i;

      vertices.push(x, y, z, x + dx, y + dy, z + dz);
      callback(vertices);
      setTimeout(() => draw(i + 1), delay);
    };

    draw(0);
  });
};

const createBranches = (depth, x, y, z, dx, dy, dz) => {
  if (depth === 0) {
    return [];
  }

  const len = Math.random();
  const endX = x + dx * len;
  const endY = y + dy * len;
  const endZ = z + dz * len;

  let vertices = [x, y, z, endX, endY, endZ];
  let geometry = new MeshLine();
  geometry.setPoints(vertices);

  let geometries = [geometry.geometry];

  const directionBias = new Vector3(endX, endY, endZ).normalize();

  for (let i = 0; i < getLoopCount(); i++) {
    let scale = 0.02*depth*depth;
    const next = new Vector3((Math.random()-0.5), ((Math.random()-1.0)), 0).normalize();
        
    const newDx = ( directionBias.x + next.x) * 10*scale / depth*2;
    const newDy = ( directionBias.y + next.y) * 3.3*scale / depth*2;
    const newDz = ( directionBias.z + next.z) * 0*scale / depth*2;
  
    let branchGeometries = createBranches(depth - 1, endX, endY, endZ, newDx, newDy, newDz);
    geometries = geometries.concat(branchGeometries);
  }

  return geometries;
};


const Tree = (props) => {
  const [geometries, setGeometries] = useState([]);

  useEffect(() => {
    setGeometries(createBranches(12, 0, 0, 0, -1, -2, 0));
  }, []);

  return (
    <group {...props}>
      {geometries.map((geometry, index) => (
        <mesh key={index} geometry={geometry}>
          <meshLineMaterial attach="material" color={0xF1F1F1} lineWidth={0.005} />
        </mesh>
      ))}
    </group>
  );
};


const Border = () => {
  const [vertices1, setVertices1] = useState([]);
  const [vertices2, setVertices2] = useState([]);
  const mesh1 = useRef();
  const mesh2 = useRef();

  useEffect(() => {
    const tl = new Vector3(-25.8, 3.7, 0);
    const bl = new Vector3(-25.8, -3.7, 0);
    const tr = new Vector3(25.3, 3.7, 0);
    const br = new Vector3(25.3, -3.7, 0);

    let verts1 = [];
    let verts2 = [];

    drawLine(bl, tl, 100, 1, verts1, setVertices1).then(() =>
      drawLine(tl, tr, 100, 3, verts1, setVertices1)
    );

    let br2 = new Vector3(br.x + 0.5, br.y, br.z);
    let tr2 = new Vector3(tr.x + 0.5, tr.y, tr.z);

    drawLine(bl, br, 100, 3, verts2, setVertices2).then(() =>
      drawLine(br2, tr2, 100, 1, verts2, setVertices2)
    );
  }, []);

  useFrame(() => {
    if (mesh1.current && mesh2.current) {
      const geometry1 = new MeshLine();
      geometry1.setPoints(vertices1);
      mesh1.current.geometry = geometry1.geometry;
      mesh1.current.material = new MeshLineMaterial({ color: 0x000000 , lineWidth: 0.01, });

      const geometry2 = new MeshLine();
      geometry2.setPoints(vertices2);
      mesh2.current.geometry = geometry2.geometry;
      mesh2.current.material = new MeshLineMaterial({ color: 0x119999, lineWidth: 0.01,  });
    }
  });

  return (
    <group>
      <mesh ref={mesh1} />
      <mesh ref={mesh2} />
    </group>
  );
};

export default function ThreeCube() {
  return (
    <InView triggerOnce={false} rootMargin="300px 0px">
      {({ inView, ref }) => (
        <div ref={ref} style={{ width: '100%', height: '100%' }}>
          {inView ? (
            <Canvas style={{ width: '100%', height: '100%' }}>
            <ambientLight color={0xFFFFFF} />
            <pointLight color={0xFFFFFF} position={[10, 10, 10]} />
              <Tree position={[3.7, 3.5, 0]} />
              <Tree position={[5.7, 5.5, 0]} />
            </Canvas>
          ) : null}
        </div>
      )}
    </InView>
  );
}
