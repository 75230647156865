import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Button, Modal,Table , Badge} from "react-bootstrap";
import Papa from 'papaparse';

function ResumeTable({ children,project }) {
  const [rotateX, setRotateX] = useState(0);
  const [rotateY, setRotateY] = useState(0);
  const [tableData, setTableData] = useState([]);

  const convertCSVToJson = (csvText) => {
    return new Promise((resolve, reject) => {
      Papa.parse(csvText, {
        header: true, // Use the first row as headers
        skipEmptyLines: true, // Skip empty lines in the CSV file
        complete: function(results) {
          resolve(results.data); // Resolve with the parsed data
        },
        error: function(error) {
          reject(error); // Reject the promise on error
        }
      });
    });
  };
  
  
  useEffect(() => {
    const handleMouseMove = (event) => {
      const x = event.clientX - window.innerWidth / 2;
      const y = event.clientY - window.innerHeight / 2;
      setRotateX(-y / 2000);
      setRotateY(x / 1000);
    };
    window.addEventListener('mousemove', handleMouseMove);

  function preprocessData(data) {
    const processedData = data.map(item => ({
      ...item,
      start: formatDate(item.start),
      end: formatDate(item.end)
    })).sort((a, b) => new Date(b.end) - new Date(a.end)); // Sort descending by start date
    return processedData;
  }
    // Fetch the CSV file from the public directory
    fetch('positions.csv')
    .then(response => response.text())  // Directly read the response as text
    .then(csvText => {
      console.log(csvText);
      convertCSVToJson(csvText).then(data => {  // Assuming convertCSVToJson accepts a CSV string
        console.log(data);

        data = preprocessData(data);

        setTableData(data);  // Update state with parsed data
      });
    })
    .catch(error => {
      console.error('Error fetching CSV file:', error);
    });  

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);
  function formatDate(dateString) {
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    const lowerCasedDate = dateString.toLowerCase(); // Convert to lower case to handle case insensitivity
  
    if (lowerCasedDate.includes("present")) {
      // If 'present' is in the string, use the current date
      return new Date().toLocaleDateString('en-US', options);
    } else {
      // Otherwise, parse the provided date string
      return new Date(dateString).toLocaleDateString('en-US', options);
    }
  }
  
  const transform = {
    transform: `perspective(600px) rotateX(${rotateX}deg) rotateY(${rotateY}deg)`,
    transition: 'transform 0.1s'
  };
    return (
      <Container>
      <Row>
        {tableData.map((item, index) => (
          <Col xs={12} md={4} key={index} className="mb-4"> {/* xs=12 ensures single column on mobile, md=4 divides the row into three columns on desktop */}
            <Card style={transform}>
              <Card.Body>
              <Card.Title>{item.title}</Card.Title>
                <Card.Text>{item.company}</Card.Text>
                <div className="mb-3">
                  <Badge bg="info" className="date-pill">
                    {formatDate(item.start)} - {formatDate(item.end)}
                  </Badge>
                </div>
                <Card.Text>{item.description}</Card.Text>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
      {children}
    </Container>
  );
}

export default ResumeTable;
